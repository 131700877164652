<template>
  <div>
    <!--- Navigator-->

    <b-row>
      <b-col cols="3">
        <h3><b>ตั้งค่า</b></h3>
        <small>
          ตั้งค่า >
          <b>ตั้งค่าการส่ง Email</b>
        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <!-- <b-button variant="primary" size="sm" style="height: 35px" class="mr-1" :disabled="isLoading">
          <span v-if="!isLoading">
            <feather-icon icon="SaveIcon" />
            บันทึก
          </span>

          <span v-else>กำลังบันทึก...</span>
        </b-button> -->
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-tabs>
            <b-tab active title="ระบบ Ticket" @click="onSelectTab('ticket')">
              <!--- START (เบอร์โทร) --->
             
              <b-row>                
                <b-col md="2" class="">
                  <label>เจ้าของ</label>
                  <v-select
                    id="brand"
                    v-model="ownerSelected"
                    label="ownerName"
                    :options="ownerOption"
                    :clearable="false"
                    class="select-size-sm"
                  />
                </b-col>

                <b-col md="3" class="">
                  <label>บริษัท</label>
                  <v-select
                    id="brand"
                    v-model="companySelected"
                    label="companyName"
                    :options="companyOption"
                    :clearable="false"
                    class="select-size-sm"
                  />
                </b-col>
                <b-col cols="2">
                <label>Brand</label>
                <v-select
                  id="brand"
                  v-model="subCompanySelected"
                  label="subCompanyName"
                  :options="subCompanyOption"
                  :clearable="false"
                  class="select-size-sm"
                  
                />
                </b-col>

                <!-- Email -->
                <b-col md="2" class="">
                    <label>Email</label>
                    <b-input-group>
                      <b-form-input
                        placeholder="Email"
                        size="sm"
                        v-model="emailInput.ticket"
                      />                     
                    </b-input-group>
                </b-col>

                <b-col md="1" class="">
                    <label>To/CC</label>                    
                    <v-select
                      id="brand"
                      v-model="emailTypeSelected"
                      label="title"
                      :options="emailType"
                      :clearable="false"
                      class="select-size-sm"
                    />
                    
                </b-col>
                <b-col md="1" class="">
                  <b-button
                          class="mt-2"
                          v-if="emailPermission=='create' || emailPermission=='edit'"
                          variant="primary"
                          size="sm"
                          :disabled="!emailInput.ticket || isLoading"
                          @click="save"
                        >
                          <feather-icon icon="SaveIcon" v-if="itemSelected!==undefined"/>
                          <feather-icon icon="PlusCircleIcon" size="14" v-else/>
                          บันทึก
                        </b-button>
                    
                </b-col>
                <!-- End Email -->
              </b-row>
              <!--- END (เบอร์โทร) --->

              
              <!-- Search -->
              <hr class="mt-2"/>
              <b-row class="mt-2" >
                
                <b-col cols="4">
                <b-form inline class="d-flex justify-content-end">
                <b-input-group class="input-group-merge" style="width: 100%">
                  <b-form-input
                    id="basic-password1"
                    type="text"
                    placeholder="ค้นหา Email/Owner/Brand"
                    v-model="filter"
                    size="sm"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="SearchIcon" class="cursor-pointer" />
                  </b-input-group-append>
                </b-input-group>
                </b-form>
                </b-col>
              </b-row>
              <!-- End Search -->

              <b-row class="mt-2">
                <b-col cols="12">
                  <b-table
                    style="font-size: 12px"
                    class="shadow"
                    small
                    striped
                    bordered
                    hover
                    responsive
                    :items="itemsTicket"
                    :fields="visibleTicketFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    :busy="isLoading"
                    :filter="filter"
                    
                    
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading...</strong>
                      </div>
                    </template>

                    <template #cell(no)="row">
                      <center>{{ row.index + 1 }}</center>
                    </template>

                    <template #cell(isTo)="row">
                      <center>
                      <b-badge pill v-if="row.item.isTo" variant="primary">To</b-badge>
                      <b-badge pill v-else variant="secondary">CC</b-badge>
                    </center>
                    </template>

                    <template #cell(action)="row" v-if="emailPermission=='create' || emailPermission=='edit'">
                      <center>
                        <b-button variant="outline-secondary" size="sm" pill  @click="onEdit('ticket', row.item)">
                          <feather-icon
                            icon="EditIcon"
                            class="cursor-pointer"
                          />
                        </b-button>

                        <b-button variant="danger" class="ml-1" size="sm" pill @click="deleteEmail(row.item.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </center>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="ระบบเบิกค่าเดินทาง"
              @click="onSelectTab('accounting')"
            >
              <!--- START (Email) --->
              <b-row class="mt-1">
                <b-col md="6" class="">
                  <label>Email</label>                  
                    <b-form-input placeholder="Email" size="sm" v-model="emailInput.accounting"/> 
                </b-col>
                <b-col md="1" class="">
                    <label>To/CC</label>                    
                    <v-select
                      id="brand"
                      v-model="emailTypeSelected"
                      label="title"
                      :options="emailType"
                      :clearable="false"
                      class="select-size-sm"
                    />                    
                </b-col>
                <b-col md="1" class="">
                  <b-button
                        v-if="emailPermission=='create' || emailPermission=='edit'" 
                        variant="primary"
                        size="sm"
                        class="mt-2"
                        :disabled="!emailInput.accounting || isLoading"
                        @click="save"
                      >
                        <feather-icon icon="SaveIcon" v-if="itemSelected!==undefined"/>
                        <feather-icon icon="PlusCircleIcon" size="14" v-else/>
                        บันทึก
                      </b-button>
                </b-col>
              </b-row>
              <!--- END (Email) --->

              <b-row class="mt-2">
                <b-col cols="12">
                  <b-table
                    style="font-size: 12px"
                    class="shadow"
                    small
                    striped
                    bordered
                    hover
                    responsive
                    :items="itemsAccounting"
                    :fields="visibleAccFields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                  >
                    <template #cell(no)="row">
                      <center>{{ row.index + 1 }}</center>
                    </template>

                    <template #cell(isTo)="row">
                      <center>
                      <b-badge pill v-if="row.item.isTo" variant="primary">To</b-badge>
                      <b-badge pill v-else variant="secondary">CC</b-badge>
                    </center>
                    </template>

                    <template #cell(action)="row" v-if="emailPermission=='create' || emailPermission=='edit'">
                      <center>
                        <b-button variant="outline-secondary" size="sm" pill @click="onEdit('accounting', row.item)">
                          <feather-icon
                            icon="EditIcon"
                            class="cursor-pointer"
                          />
                        </b-button>

                        <b-button variant="danger" class="ml-1" size="sm" pill @click="deleteEmail(row.item.id)">
                          <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </center>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>      
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BBadge,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BFormRadioGroup,
  BTabs,
  BTab,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import TicketField from "./field/ticket";
import AccField from "./field/account";

//SERVICES
import CustomerService from "@/services/setting/customer";
import EmailService from "@/services/setting/email";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BBadge,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BPagination,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BFormRadioGroup,
    BTabs,
    BTab,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      ticketFields: TicketField,
      accFields: AccField,
      itemsTicket: [],
      itemsAccounting:[],
      ownerSelected: {},
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],
      emailType:[{title:"CC", value:0},{title:"To", value:1}],
      emailTypeSelected: {title:"CC", value:0},
      emailInput: { ticket: "", accounting: "" },
      isLoading: false,
      tabSelected: "ticket",
      itemSelected:null
    };
  },


  computed:{
    emailPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST008")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    visibleTicketFields() {
        return this.ticketFields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST008") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    },
    visibleAccFields() {
        return this.accFields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST008") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }
  },
  watch: {
    ownerSelected(newValue, oldValue) {
      this.getCustomerCompany(newValue);
    },

    companySelected(newValue, oldValue) {
      this.getCustomerSubCompany(newValue);
    },

    tabSelected(newValue, oldValue){
      if(newValue=="accounting"){      
        this.getEmailAccounting()
      }
      if(newValue=="ticket"){      
        this.getEmailTicket()
      }
      
    }
  },
  mounted() {
    this.getCustomerOwner();  
    this.getEmailTicket()
  },
  methods: {
    validateEmail(email) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(String(email).toLowerCase());
    },
    async getCustomerOwner() {
      this.isLoading = true;
      const result = await CustomerService.GetCustomerOwnerList({});

      this.ownerOption = result.data.data;
      if (result.data.data.length > 0) {
        this.ownerSelected = result.data.data[0];
      }
      this.isLoading = false;
    },

    async getCustomerCompany(ownerObj) {
      this.isLoading = true;
      const result = await CustomerService.GetCustomerCompanyList(ownerObj.id);
      this.companyOption = result.data.data;

      if (result.data.data.length > 0) {
        //WHEN EDIT
        if(this.itemSelected){
          const find = this.companyOption.find(f=>f.id == this.itemSelected.companyId)
          this.companySelected = find
          await this.getCustomerSubCompany(find)
        }else{
          this.companySelected = result.data.data[0];
        }
        
      }     
      this.isLoading = false;
    },
    async getCustomerSubCompany(companyObj) {
      const result = await CustomerService.GetCustomerSubCompanyList(
        companyObj.id
      );
      this.subCompanyOption = result.data.data;

      if (result.data.data.length > 0) {
        this.subCompanySelected = result.data.data[0];
      }  
    },

    async getEmailTicket(companyObj) {
      this.isLoading = true;
      const result = await EmailService.GetEmailTicketList(this.tabSelected);
      this.itemsTicket = result.data;
      this.isLoading = false;
    },
    async getEmailAccounting() {
      this.isLoading = true;
      const result = await EmailService.GetEmailAccountingList(this.tabSelected);
      this.itemsAccounting = result.data;
      this.isLoading = false;
    },

    async save(){
      const inputEmail = this.emailInput[this.tabSelected]      
      if(!this.validateEmail(inputEmail)){
        alert("กรุณาตรวจสอบรูปแบบการกรอก Email")
        return
      }
      if(!this.itemSelected){
        await this.insertEmail()
      }else{
        await this.updateEmail()
      }
    },

    async insertEmail() {
      this.isLoading = true;
      let obj = {
        ownerId: this.ownerSelected.id || 0,
        companyId: this.companySelected.id || 0,
        subCompanyId: this.subCompanySelected.id || 0,
        email: this.emailInput[this.tabSelected],
        module: this.tabSelected,
        isTo:this.emailTypeSelected.value || 0
      };

      if(obj.module == "accounting"){
        delete obj.ownerId
        delete obj.companyId
      }

      const result = await EmailService.InsertEmail(obj);
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่า Email`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });
        this.emailInput.ticket = ""
        this.emailInput.accounting = ""
        this.emailTypeSelected = {title:"CC", value:0}

        if(this.tabSelected=="ticket"){
          await this.getEmailTicket(this.companySelected);
        }else{
          await this.getEmailAccounting()
        }
        
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่า Email`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }

      this.isLoading = false;
    },

    async updateEmail(){      
      this.isLoading = true;
      let obj = {
        ownerId: this.ownerSelected.id || 0,
        companyId: this.companySelected.id || 0,
        subCompanyId: this.subCompanySelected.id || 0,
        email: this.emailInput[this.tabSelected],
        module: this.tabSelected,
        isTo:this.emailTypeSelected.value || 0
      };

    

      const result = await EmailService.UpdateEmail(obj, this.itemSelected.id)
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่า Email`,
            icon: "CheckIcon",
            variant: "success",
            text: `แก้ไขรายการเรียบร้อย`,
          },
        });
        this.emailInput.ticket = ""
        this.emailInput.accounting = ""
        this.itemSelected = null
        this.emailTypeSelected = {title:"CC", value:0}

        if(this.tabSelected=="ticket"){
          await this.getEmailTicket(this.companySelected);
        }else{
          await this.getEmailAccounting()
        }
        
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่า Email`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }

      this.isLoading = false;
    },

    async deleteEmail(id){
      const isConfirm = confirm("ต้องการลบรายการนี้หรือไม่?")
      if(!isConfirm){
        return false
      }

      const result = await EmailService.DeleteEmail(id)
      if (result.status == "success") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่า Email`,
            icon: "CheckIcon",
            variant: "success",
            text: `ลบรายการเรียบร้อย`,
          },
        });        
     
        if(this.tabSelected=="ticket"){
          await this.getEmailTicket(this.companySelected);
        }else{
          await this.getEmailAccounting()
        }

      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `ตั้งค่า Email`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการลบรายการ`,
          },
        });
      }
    },

    async onEdit(module, item){     
      this.itemSelected = item    
      this.emailInput[module] = item.email

      
      if(module=="ticket"){
        const findOwner = this.ownerOption.find(f=>f.id == item.ownerId)
        this.ownerSelected = findOwner
        await this.getCustomerCompany(findOwner)
      }

      const filterEmailType = this.emailType.filter(f=>f.value == item.isTo)
      this.emailTypeSelected = filterEmailType
        
    },

  

    onSelectTab(tab) {
      this.tabSelected = tab;
    },
  },
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
