import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const EmailService = () => {}

EmailService.GetEmailTicketList = async(module)=> {  
    return await AXIOS_SERVICE('get', `${BaseUrl}/setting/email/module/${module}`, {})
}

EmailService.GetEmailAccountingList = async(module)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/setting/email/module/${module}`, {})
}

EmailService.InsertEmail = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/setting/email`, payload)
} 

EmailService.UpdateEmail = async(payload, id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/setting/email/${id}`, payload)
} 

EmailService.DeleteEmail = async(emailId)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/setting/email/${emailId}`, {})
} 
 
 export default EmailService