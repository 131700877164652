export default [
  { key: "no", label: "ลำดับที่", sortable: true , thStyle:{color:"white",width: "10% !important"}},
  {
    key: "email",
    label: "Email",
    sortable: true,
    thStyle:{color:"white"}
  },  
  {
    key: "ownerName",
    label: "เจ้าของ",
    sortable: true,
    thStyle:{color:"white"}
  },  
  {
    key: "companyName",
    label: "บริษัท",
    sortable: true,
    thStyle:{color:"white"}
  },  
  {
    key: "subCompanyName",
    label: "Brand",
    sortable: true,
    thStyle:{color:"white"}
  },  
  {
    key: "isTo",
    label: "to/cc",
    sortable: true,
    thStyle:{color:"white",width: "10% !important",textAlign: "center"}
  }, 
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "15% !important", textAlign: "center", color:"white" },
  },
];
